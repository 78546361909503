import React from "react";
import PropTypes from "prop-types";
import { isServer } from "@bkry/bowline-redux";
import { useSelector } from "react-redux";
import { Alert, Button } from "reactstrap";
import { getContractAttributes } from "@bkry/bowline-redux/contracts";
import { useWeb3React } from "@web3-react/core";
import { chains } from "@bkry/bowline-connectors";
import { ethers } from "ethers";
const NetworkBar = ({ contractByAddress, stakingContractItem }, context) => {
  let compResponse = null;
  const { connector } = useWeb3React();
  const contractData = useSelector((state) =>
    getContractAttributes(state, contractByAddress)
  );

  const handleNetworkChangePolygon = async () => {
    try {
      let chain = await connector?.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x89" }],
      });
      console.log("chain", chain);
    } catch (switchError) {
      console.log("error", switchError);
      if (switchError.code === 4902) {
        try {
          let chain = await connector?.provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                ...chains.getAddChainParameters(137),
                chainId: "0x89",
              },
            ],
          });
          console.log("chain", chain);
        } catch (addError) {
          console.log("error", addError);
        }
      }
    }
  };
  const handleNetworkChangeMumbai = async () => {
    try {
      let chain = await connector?.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x13881" }],
      });
    } catch (switchError) {
      console.log("error", switchError);
      if (switchError.code === 4902) {
        try {
          let chain = await connector?.provider.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                ...chains.getAddChainParameters(80001),
                chainId: "0x13881",
                chainName: "Mumbai",
              },
            ],
          });
          console.log("wallet_addEthereumChain", chain);
        } catch (addError) {
          console.log("wallet_addEthereumChain error", addError);
        }
      }
    }
  };
  const handleNetworkChange = async (chainId) => {
    try {
      let chain = await connector?.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId }],
      });
      console.log("chain", chain);
    } catch (switchError) {
      console.log("error", switchError);
    }
  };

  const networkVersion =
    typeof connector?.provider?.networkVersion === "string" &&
    connector?.provider?.networkVersion;

  //GENERAL CASE
  if (
    !isServer &&
    !contractData?.network &&
    !stakingContractItem?.network &&
    networkVersion !== "1"
  ) {
    compResponse = (
      <Alert color="danger">
        <p className="small">
          {context.t(
            "You are currently connected to a different network than this contract is deployed on. Please change your network to {networkName} in your wallet.",
            { networkName: "Ethereum" }
          )}
        </p>
      </Alert>
    );
  }

  // UTILITY CONTRACT CASES
  if (
    !isServer &&
    stakingContractItem?.network === "rinkeby" &&
    networkVersion !== "4"
  ) {
    compResponse = (
      <Alert color="danger">
        <p className="small">
          {context.t(
            "You are currently connected to a different network than this contract is deployed on. Please change your network to {networkName} in your wallet.",
            { networkName: "Ethereum" }
          )}
        </p>
      </Alert>
    );
  }
  if (
    !isServer &&
    stakingContractItem?.network === "goerli" &&
    networkVersion !== "5"
  ) {
    compResponse = (
      <Alert color="danger">
        <p className="small">
          {context.t(
            "You are currently connected to a different network than this contract is deployed on. Please change your network to {networkName} in your wallet.",
            { networkName: "Görli Test" }
          )}
        </p>
      </Alert>
    );
  }
  if (
    !isServer &&
    stakingContractItem?.network === "ethereum" &&
    networkVersion !== "1"
  ) {
    compResponse = (
      <Alert color="danger">
        <p className="small">
          {context.t(
            "You are currently connected to a different network than this contract is deployed on. Please change your network to {networkName} in your wallet.",
            { networkName: "Ethereum" }
          )}
        </p>
        <div className="d-flex">
          <Button
            className="ms-auto"
            color="primary"
            onClick={async () => handleNetworkChange("0x1")}
          >
            {context.t("Switch to Ethereum network")}
          </Button>
        </div>
      </Alert>
    );
  }

  //TOKEN CONTRACT CASES
  if (
    !isServer &&
    contractData?.network === "rinkeby" &&
    networkVersion !== "4"
  ) {
    compResponse = (
      <Alert color="danger">
        <p className="small">
          {context.t(
            "You are currently connected to a different network than this contract is deployed on. Please change your network to {networkName} in your wallet.",
            { networkName: "Rinkeby Test" }
          )}
        </p>
        <div className="d-flex">
          <Button
            className="ms-auto"
            color="primary"
            onClick={async () => handleNetworkChange("0x4")}
          >
            {context.t("Switch to Rinkeby network")}
          </Button>
        </div>
      </Alert>
    );
  }
  if (
    !isServer &&
    contractData?.network === "goerli" &&
    networkVersion !== "5"
  ) {
    compResponse = (
      <Alert color="danger">
        <p className="small">
          {context.t(
            "You are currently connected to a different network than this contract is deployed on. Please change your network to {networkName} in your wallet.",
            { networkName: "Görli Test" }
          )}
        </p>
        <div className="d-flex">
          <Button
            className="ms-auto"
            color="primary"
            onClick={async () => handleNetworkChange("0x5")}
          >
            {context.t("Switch to Görli network")}
          </Button>
        </div>
      </Alert>
    );
  }
  if (
    !isServer &&
    contractData?.network === "ethereum" &&
    networkVersion !== "1"
  ) {
    compResponse = (
      <Alert color="danger">
        <p className="small">
          {context.t(
            "You are currently connected to a different network than this contract is deployed on. Please change your network to {networkName} in your wallet.",
            { networkName: "Ethereum" }
          )}
        </p>
        <div className="d-flex">
          <Button
            className="ms-auto"
            color="primary"
            onClick={async () => handleNetworkChange("0x1")}
          >
            {context.t("Switch to Ethereum network")}
          </Button>
        </div>
      </Alert>
    );
  }
  if (
    !isServer &&
    contractData?.network === "polygon" &&
    networkVersion !== "137"
  ) {
    compResponse = (
      <Alert color="danger" className="text-center">
        <p className="small">
          {context.t(
            "You are currently connected to a different network than this contract is deployed on. Please change your network to {networkName} in your wallet.",
            { networkName: "Polygon" }
          )}
        </p>
        <div className="d-flex">
          <Button
            className="ms-auto"
            color="primary"
            onClick={handleNetworkChangePolygon}
          >
            {context.t("Switch to Polygon network")}
          </Button>
        </div>
      </Alert>
    );
  }

  if (
    !isServer &&
    contractData?.network === "mumbai" &&
    networkVersion !== "80001"
  ) {
    compResponse = (
      <Alert color="danger" className="text-center">
        <p className="small">
          {context.t(
            "You are currently connected to a different network than this contract is deployed on. Please change your network to {networkName} in your wallet.",
            { networkName: "Mumbai" }
          )}
        </p>
        <div className="d-flex">
          <Button
            className="ms-auto"
            color="primary"
            onClick={handleNetworkChangeMumbai}
          >
            {context.t("Switch to Mumbai network")}
          </Button>
        </div>
      </Alert>
    );
  }
  return compResponse;
};

/**  define proptype for the 'translation' function  */
NetworkBar.contextTypes = {
  t: PropTypes.func,
};

NetworkBar.propTypes = {
  contractByAddress: PropTypes.string,
};

export default NetworkBar;
