import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { pageAttributes } from "@bkry/bowline-redux/pages";

const HiddenIfEditorLoaded = (props) => {
  const { slug, children } = props;

  const loadedProject = useSelector((state) => state.projects.loadedShow);
  const loadedPage = useSelector((state) => state.pages.loadedPage);
  const showErrorPage = useSelector((state) => state.pages.showError);
  const pageTemp = useSelector(pageAttributes);

  return (
    <>
      {loadedProject && (loadedPage || showErrorPage) && pageTemp?.slug != slug
        ? children
        : null}
    </>
  );
};

HiddenIfEditorLoaded.propTypes = {
  children: PropTypes.any.isRequired,
  slug: PropTypes.string.isRequired,
};

export default HiddenIfEditorLoaded;
