/**
 * The Events container
 */
import { ConnectWallet } from "@bkry/bowline-components/ConnectWallet";
import { ErrorModal } from "@bkry/bowline-components/ErrorModal";
import { ContractMints } from "@bkry/bowline-components/Minting";
import { Page } from "@bkry/bowline-components/Page";
import { EditorLoader } from "@bkry/bowline-components/PageBuilder";
import { PageHead } from "@bkry/bowline-components/PageHead";
import { SuccessModal } from "@bkry/bowline-components/SuccessModal";
import { TransactionProgressModal } from "@bkry/bowline-components/TransactionProgressModal";
import { ScrollToTop as useScrollToTop } from "@bkry/bowline-components/Utils";
import {
  mintErrorReset,
  resetTransactionSuccess,
} from "@bkry/bowline-redux/contracts";
import {
  getAvailableMintTierContracts,
  listTiersForProject,
} from "@bkry/bowline-redux/mintTiers";
import { env } from "@bkry/bowline-utils";
import { useWeb3React } from "@web3-react/core";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import HiddenIfEditorLoaded from "../../components/HiddenIfEditorLoaded";

const { REACT_APP_PROJECT_ID } = env;
/**
 * MintingSection container used as app default landing page
 */
const MintingSection = (props, context) => {
  useScrollToTop();
  const dispatch = useDispatch();
  const { account } = useWeb3React();

  const mintSuccess = useSelector((state) => state?.contracts?.mintSuccess);

  console.log("mint success", mintSuccess);

  const mintError = useSelector((state) => state?.contracts?.mintError);

  const mintErrorMessage = useSelector(
    (state) => state?.contracts?.mintErrorMessage
  );

  const mintingInProgress = useSelector(
    (state) => state?.contracts?.mintingInProgress
  );
  const awaitingTransactionConfirmation = useSelector(
    (state) => state?.contracts?.awaitingTransactionConfirmation
  );

  const mintPending = useSelector((state) => state?.contracts?.mintPending);

  useEffect(() => {
    account &&
      dispatch(
        listTiersForProject({
          projectId: REACT_APP_PROJECT_ID,
          address: account,
        })
      );
    window.scrollTo(0, 0);
  }, [dispatch, REACT_APP_PROJECT_ID, account]);

  const mintTierIds = useSelector((state) => state?.mintTiers?.idsList);

  const contracts = useSelector((state) =>
    getAvailableMintTierContracts(state, mintTierIds)
  );

  return (
    <Page id="MintingSection" title={context.t("Minting Section")}>
      <Container>
        <HiddenIfEditorLoaded slug="minting">
          <PageHead title={context.t("Minting Section")} backTarget={"/"} />
        </HiddenIfEditorLoaded>
      </Container>
      <EditorLoader pageSlug={"minting"} />
      <Container>
        <div className="ml-5 d-flex flex-column minting-wrapper">
          {account ? (
            <>
              {contracts.map((contractAddress) => (
                <ContractMints
                  contractAddress={contractAddress}
                  key={contractAddress}
                />
              ))}
            </>
          ) : (
            <Row className="justify-content-center">
              <Col md={7} className="text-center">
                <h3 className="fw-bold mt-3 mb-5">
                  {context.t("Please connect your Wallet")}
                </h3>
                <p className="lead">
                  {context.t(
                    "Sign in with your crypto wallet or Google account to mint NFTs."
                  )}
                </p>
                <Row>
                  <div className="col-sm-12 col-md-6 mx-auto">
                    <ConnectWallet
                      buttonColor="primary"
                      buttonId="minting-connect-wallet"
                      block={true}
                      className="mt-5 button-width"
                    />
                  </div>
                </Row>
              </Col>
            </Row>
          )}
          <div className="mt-5 mb-3">
            <a
              href="https://www.bowline.app/tos"
              target="_blank"
              rel="noreferrer"
              className="small"
            >
              {context.t("Terms and Conditions")}
            </a>
          </div>
        </div>
      </Container>
      <SuccessModal
        success={mintSuccess || false}
        // redirectUrl={`/minting`} TODO needed here?
        reset={resetTransactionSuccess}
        resourceType="token"
        action="minted"
      />
      <TransactionProgressModal
        transactionInProgress={mintingInProgress}
        awaitingTransactionConfirmation={awaitingTransactionConfirmation}
        transactionPending={mintPending}
      />
      {mintErrorMessage?.errorMessage !== "transaction is not defined" && (
        <ErrorModal
          error={mintError}
          errorReset={mintErrorReset}
          customErrorBody={mintErrorMessage?.errorMessage}
        />
      )}
    </Page>
  );
};

/**  define proptype for the 'translation' function  */
MintingSection.contextTypes = {
  t: PropTypes.func,
};

/** export the component as redux connected component */
export default withRouter(MintingSection);
