/**
 * The Events Root container
 */
import React from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";

/**
 * Events Root container, the landing page for logged in users
 */

const MintingSection = Loadable({
  loader: () =>
    import(/* webpackChunkName: "MintingSection" */ "./MintingSection"),
  loading: () => null,
  modules: ["MintingSection"],
});

const MintingTierDetail = Loadable({
  loader: () =>
    import(/* webpackChunkName: "MintingTierDetail" */ "./MintingTierDetail"),
  loading: () => null,
  modules: ["MintingTierDetail"],
});

const MintingSectionRoot = () => {
  return (
    <div className="MintingSection">
      <Switch>
        <Route path={`/minting/:mintTierId`} component={MintingTierDetail} />

        <Route path={`/minting`} component={MintingSection} />
      </Switch>
    </div>
  );
};

/**  define proptype for the 'translation' function  */
MintingSectionRoot.contextTypes = {
  t: PropTypes.func,
};

/** export the component as redux connected component */
export default MintingSectionRoot;
